import {Component, EventEmitter, Inject, InjectionToken, Output} from '@angular/core';
import {RecaptchaModule} from "ng-recaptcha";
import {AsyncPipe, NgIf} from "@angular/common";

export interface CoreCaptchaDefaultOptions {
  recaptchaSiteKey:string,
}

export const CORE_CAPTCHA_DEFAULT_OPTIONS = new InjectionToken<CoreCaptchaDefaultOptions>('CORE_CAPTCHA_DEFAULT_OPTIONS');



@Component({
  selector: 'core-captcha',
  standalone: true,
  imports: [
    RecaptchaModule,
    AsyncPipe,
    NgIf
  ],
  templateUrl: './captcha.component.html',
  styleUrl: './captcha.component.css'
})
export class CaptchaComponent {

  @Output()
  captchaResoluOuExpire = new EventEmitter<string | null>();

  recaptchaSiteKey:string;

  constructor(@Inject(CORE_CAPTCHA_DEFAULT_OPTIONS) defaultOptions : CoreCaptchaDefaultOptions) {
    this.recaptchaSiteKey = defaultOptions.recaptchaSiteKey;
  }

  captchaResolved($event: string | null) {
    this.captchaResoluOuExpire.emit($event)
  }
}
