<mat-autocomplete (optionSelected)="onUtilisateurASelectionneUnIndicatif($event)"
                  [autoActiveFirstOption]="true" class="choixIndicatifsPanel" #auto="matAutocomplete">
  @for (pays of getPaysFiltres(); track pays) {
    <mat-option value="{{ pays.indicatifTelephonique }}"><span
      class="country-flag">{{ pays.iso2 | codeToFlagEmoji }}</span> {{ pays.indicatifTelephonique }}
    </mat-option>
  }
</mat-autocomplete>
<span class="country-flag">{{getPremierDrapeauCorrespondantAIndicatif()  | codeToFlagEmoji}}</span>

<input type="text"
       matInput
       #indicatifInput="matInput"
       [placeholder]="partieIndicatifPlaceholder?? defaultOptions?.placeholders?.indicatif??''"
       name="indicatifTelephonique"
       [disabled]="disabled"
       [required]="required"
       class="identifiantTelephoniqueInput"
       [value]="indicatif"
       (input)="onUtilisateurASaisieUnIndicatif($event)"
       (focusin)="onUtilisateurAPrisFocusSurIndicatif(indicatifInput)"
       (blur)="onUtilisateurABlurFocusSurIndicatif(indicatifInput)"
       [matAutocomplete]="auto">


<input [placeholder]="partieTelephonePlaceholder?? defaultOptions?.placeholders?.telephone??''" [disabled]="disabled" [required]="required" matInput #telephoneInput="matInput"
       class="telephoneInput" type="text" name="telephone" [value]="telephone"
       (input)="onUtilisateurAInputTelephone($event)" (focus)="onUtilisateurAPrisFocusSurTelephone($event)"
       (blur)="onUtilisateurABlurFocusSurTelephone($event)"/>
