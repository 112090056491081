/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Directive,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  forwardRef
} from '@angular/core';
import {InteractionStatus, ModelDirective} from '@core/forms/model.directive';
import {FieldModelStatus, getNodeErrorMessage, getNodeLockMessage} from '@core/validation/model.validation';
import {ModelFieldDirectiveBase} from '@core/forms/model-field.directive';
import {MatTooltip} from '@angular/material/tooltip';
import {
  BehaviorSubject, ReplaySubject, Subject, Subscription, combineLatest, first, switchMap, tap, merge
} from 'rxjs';
import {SaisieTelephoneComponent} from "@core/material-ui/components/saisie-telephone/saisie-telephone.component";

@Directive({
// eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-form-field[telephoneCoreModelField][indicatifCoreModelField][saisieTelephone]',
  providers: [
    {provide: ModelFieldDirectiveBase, useExisting: forwardRef(() => SaisieTelephoneFormFieldDirective)},
    {provide: MatTooltip, useClass: MatTooltip}
  ],
  standalone: true
})
export class SaisieTelephoneFormFieldDirective extends ModelFieldDirectiveBase<any> implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  set telephoneCoreModelField(value: string) {
    this._telephoneCoreModelField = value;
    this.fieldNames = [this._telephoneCoreModelField, this._indicatifCoreModelField].filter(f => f != null);
  }

  private _telephoneCoreModelField!: string;

  @Input()
  set indicatifCoreModelField(value: string) {
    this._indicatifCoreModelField = value;
    this.fieldNames = [this._indicatifCoreModelField, this._indicatifCoreModelField].filter(f => f != null);
  }

  private _indicatifCoreModelField!: string;


  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('saisieTelephone')
  component!: SaisieTelephoneComponent;

  private fieldStatus$: BehaviorSubject<{ [fieldName: string]: FieldModelStatus<any> }> = new BehaviorSubject<{ [fieldName: string]: FieldModelStatus<any> }>({});
  private fieldValue$: BehaviorSubject<{ [fieldName: string]: any }> = new BehaviorSubject<{ [fieldName: string]: any }>({});
  private interactionStatus$: BehaviorSubject<{ [fieldName: string]: InteractionStatus | null }> = new BehaviorSubject<{ [fieldName: string]: InteractionStatus | null }>({});
  private afterViewInit$: Subject<boolean> = new ReplaySubject<boolean>(1);
  private subscription?: Subscription;

  constructor(parent: ModelDirective<any>,
              private vcr: ViewContainerRef,
              private tooltip: MatTooltip) {
    super(parent);
  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next(true);
  }

  ngOnInit(): void {
    super.onInit();

    this.tooltip.tooltipClass = "core-form-field-tooltip";

    const applyFieldValue = this.fieldValue$.pipe(
      tap(fieldValue => {
        this.component.telephone = fieldValue[this._telephoneCoreModelField];
        this.component.indicatif = fieldValue[this._indicatifCoreModelField];
      })
    );

    const applyFieldStatus = combineLatest([
      this.fieldStatus$,
      this.interactionStatus$
    ]).pipe(
      switchMap(([fieldStatuses, interactionStatuses]) => {

        const errorMessages: Array<string> = Object.getOwnPropertyNames(fieldStatuses).reduce((acc,fieldKey)=>{
          const fieldStatus = fieldStatuses[fieldKey];
          return [...acc, ...getNodeErrorMessage(fieldStatus)];
        }, [] as Array<string>);

        const lockMessages: Array<string> = Object.getOwnPropertyNames(fieldStatuses).reduce((acc,fieldKey)=>{
          const fieldStatus = fieldStatuses[fieldKey];
          return [...acc, ...getNodeLockMessage(fieldStatus)];
        }, [] as Array<string>);

        const metaInteractionStatus = Object.getOwnPropertyNames(interactionStatuses).reduce((acc,fieldKey)=>{
          if(acc === 'dirty')
            return acc as InteractionStatus;

          const interactionStatus = interactionStatuses[fieldKey];
          if(interactionStatus == 'dirty' || interactionStatus == 'touched')
            return interactionStatus;

          return acc;
        }, 'pristine' as InteractionStatus) as InteractionStatus;

        const allMessages = errorMessages.concat(lockMessages);

        if (allMessages.length > 0) {
          this.tooltip.message = allMessages.join('\n');
        } else {
          //Remove error css class
          this.tooltip.message = '';
        }

        return this.afterViewInit$.pipe(
          first(),
          tap(() => {
            //Add error css class
            const element: HTMLElement = this.vcr.element.nativeElement;
            const matForFieldParent = element.closest('mat-form-field');

            if (metaInteractionStatus !== 'pristine') {
              //On n'affiche pas le style d'erreur sur les champs si l'utilisateur n'a pas encore touché le champ
              if (errorMessages.length > 0) {
                matForFieldParent?.classList.add('core-form-field-invalid');
              } else {
                matForFieldParent?.classList.remove('core-form-field-invalid');
              }
            }

            if (lockMessages.length > 0) {
              matForFieldParent?.classList.add('core-form-field-locked');
            } else {
              matForFieldParent?.classList.remove('core-form-field-locked');
            }
          })
        );
      })
    );

    const registerToFieldChanged =
      merge(
        this.afterViewInit$.pipe(
          first(),
          switchMap(() => {
            return this.component.utilisateurAModifieTelephone;
          }),
          tap((value) => {
            this.onFieldValueChanged(value, this._telephoneCoreModelField as any);
          })),
        this.afterViewInit$.pipe(
          first(),
          switchMap(() => {
            return this.component.utilisateurAModifieIndicatif;
          }),
          tap((value) => {
            this.onFieldValueChanged(value, this._indicatifCoreModelField as any);
          }))
      );


    const registerToInteracted =
      merge(
        this.afterViewInit$.pipe(
          first(),
          switchMap(() => {
            return this.component.utilisateurAInteragisAvecTelephone;
          }),
          tap((values) => {
            this.onUserTouched(this._telephoneCoreModelField as any);
            this.parent.userInteracted.next({fieldName: this._telephoneCoreModelField, interaction: values});
          })),
        this.afterViewInit$.pipe(
          first(),
          switchMap(() => {
            return this.component.utilisateurAInteragisAvecIndicatif;
          }),
          tap((values) => {
            this.onUserTouched(this._indicatifCoreModelField as any);
            this.parent.userInteracted.next({fieldName: this._indicatifCoreModelField, interaction: values});
          }))
      );

    this.subscription = combineLatest([
      applyFieldStatus, applyFieldValue, registerToFieldChanged, registerToInteracted
    ]).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    super.onDestroy();
  }

  @HostListener('mouseover') mouseover() {
    this.tooltip.show();
  }

  @HostListener('click') touched() {
    this.tooltip.show();
  }

  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
  }

  applyFieldStatus(fieldStatus: FieldModelStatus<any>, fieldName:any): void {
    const previousValue = this.fieldStatus$.getValue();

    this.fieldStatus$.next({
      ...previousValue,
      [fieldName]:fieldStatus
    });
  }

  applyFieldValue(fieldValue: any, fieldName:any): void {
    const previousValue = this.fieldValue$.getValue();

    this.fieldValue$.next({
      ...previousValue,
      [fieldName]: fieldValue
    });
  }

  override applyInteractionStatus(interactionStatus: InteractionStatus, fieldName:any): void {
    const previousValue = this.interactionStatus$.getValue();

    this.interactionStatus$.next({
      ...previousValue,
      [fieldName]: interactionStatus
    });
  }
}


